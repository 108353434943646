import './styles.css';
import { BoxModal } from '../../../components/boxModal';
import { PixKey } from '../pix-key'
import * as RadioGroup from '@radix-ui/react-radio-group';
import BeatLoader from "react-spinners/BeatLoader";
import { useEffect, useState } from 'react';
import { usePixKey } from '../../../hooks/usePixKey';

export const CreatePix = ({handleCloseModal, userData, loader, override, handleCreateNewKey, showKeyDeletedModal, typeKey, setTypeKey, isPF, showConfirmationCodeModal, closeConfirmationCodeModal, startPuller}) => {
  const { pixKeys } = usePixKey();
  
  const [keysStatus, setKeysStatus] = useState({
    hasTaxNumberKey: false,
    hasEmailKey: false,
    hasPhoneKey: false,
  });

  const verifyPixKey = (key) => {
    const taxNumber = userData.taxNumber.replace(/[^\d]+/g, "");
    const email = userData.mail;
    const phoneNumber = `+55${userData.phoneNumber.replace(/[^\d]+/g, "")}`;

    switch(key){
      case taxNumber: {
        setKeysStatus(prevState => ({ ...prevState, hasTaxNumberKey: true }));
        break;
      }
      case email: {
        setKeysStatus(prevState => ({ ...prevState, hasEmailKey: true }));
        break;
      }
      case phoneNumber: {
        setKeysStatus(prevState => ({ ...prevState, hasPhoneKey: true }));
        break;
      }
    }
  }

  function ResetKeyType(){
    setTypeKey(null);
  }

  useEffect(()=>{
    ResetKeyType();
  }, []);

  useEffect(()=>{
    pixKeys.map((pixKey)=>{
      verifyPixKey(pixKey.key);
    })
  }, [pixKeys]);

  return (
    <BoxModal title='Gerenciamento de Chaves Pix' maxWidth={800} maxHeight={801} close={handleCloseModal}>
            <div className='keys-modal-content'>
              <div className='keys-modal-section'>
                <h3>Minhas Chaves</h3>

                <div className='my-keys'>
                  {
                    !loader
                    ? (
                      <>
                        {
                          pixKeys.length
                          ? (
                            pixKeys.map((item) => (
                              <PixKey
                                key={item.id}
                                id={item.id}
                                type={item.type}
                                keyValue={item.key}
                                startPuller={startPuller}
                                status={item.status}
                                showConfirmationCodeModal={showConfirmationCodeModal}
                                showKeyDeletedModal={showKeyDeletedModal}
                                setTypeKey={setTypeKey}
                                loader={loader}
                              />
                            ))
                          ) : (
                            <span>Sem chaves no momento.</span>
                          )
                        }
                      </>
                    ) : (
                      <BeatLoader
                        color={"var(--secondary-color)"}
                        loading={loader}
                        css={override}
                        size={20}
                      />
                    )
                  }
                </div>
              </div>

              <div className='keys-modal-section'>
                <h3>Cadastrar nova Chave</h3>

                <form className='new-key-container' onSubmit={handleCreateNewKey}>
                  <RadioGroup.Root
                    className="new-key-radio-root"
                    defaultValue="4"
                    value={typeKey}
                    onValueChange={(value) => setTypeKey(value)}
                    id='cpf'
                    required
                  >
                    <div className='new-key-radio-item'>
                      <RadioGroup.Item className="new-key-radio-button" value={isPF? 0 : 1} id={isPF ? "cpf" : "cnpj"} disabled={keysStatus.hasTaxNumberKey}>
                        <RadioGroup.Indicator className="new-key-radio-indicator" />
                      </RadioGroup.Item>
                      <label className="new-key-radio-label" htmlFor={isPF ? "cpf" : "cnpj"}>
                        {isPF ? "CPF" : "CNPJ"} {keysStatus.hasTaxNumberKey && ' - Chave já cadastrada'}
                        <strong>{userData?.taxNumber}</strong>
                      </label>
                    </div>

                    <div className='new-key-radio-item'>
                      <RadioGroup.Item className="new-key-radio-button" value={2} id="email" disabled={keysStatus.hasEmailKey}>
                        <RadioGroup.Indicator className="new-key-radio-indicator" />
                      </RadioGroup.Item>
                      <label className="new-key-radio-label" htmlFor="email">
                        E-mail {keysStatus.hasEmailKey && ' - Chave já cadastrada'}
                        <strong>{userData?.mail}</strong>
                      </label>
                    </div>

                    <div className='new-key-radio-item'>
                      <RadioGroup.Item className="new-key-radio-button" value={3} id="tel" disabled={keysStatus.hasPhoneKey}>
                        <RadioGroup.Indicator className="new-key-radio-indicator" />
                      </RadioGroup.Item>
                      <label className="new-key-radio-label" htmlFor="tel">
                        Celular {keysStatus.hasPhoneKey && ' - Chave já cadastrada'}
                        <strong>{userData?.phoneNumber}</strong>
                      </label>
                    </div>

                    <div className='new-key-radio-item'>
                      <RadioGroup.Item className="new-key-radio-button" value={4} id="random">
                        <RadioGroup.Indicator className="new-key-radio-indicator" />
                      </RadioGroup.Item>
                      <label className="new-key-radio-label" htmlFor="random">
                        <strong>Chave aleatória</strong>
                      </label>
                    </div>

                  </RadioGroup.Root>

                  <button disabled={(typeKey == null ? true : loader)} className='new-key-button'>{loader ? 'Solicitando...':'Solicitar chave pix'}</button>
                </form>
              </div>
            </div>
          </BoxModal>
  );
}
const KEY_TYPE = {
  CPF: '0',
  CNPJ: '1',
  MAIL: '2',
  PHONE: '3',
  RANDOM: '4',
}

import bankList from '../services/bankList.json';

export const getBankCode = (code) => {
  if(!code){
    return "Banco não informado"
  }
  const bank = bankList.find(bank => +bank.code === +code) || null;
  if(!bank){
    return "Banco não informado"
  }
  return bank?.name.split(" ")[0] + " " +bank?.name.split(" ")[1] || '';
}

import { TbCircleKey, TbCopy, TbClipboardCheck, TbTrash } from "react-icons/tb";
import { BsCheckSquare, BsXSquare, BsX } from 'react-icons/bs'
import { toast } from "react-toastify";
import { useState } from "react";
import { pixTypes, pixKeyStatus } from "../../../services/enums";
import { useEffect } from "react";
import { deleteKey } from "../../../services/pix.service";

import './styles.css'
import { useNavigate } from "react-router";
import { InfoStatusButton } from "../../../components/acesso-rapido/pix/pix-key-card/InfoStatusButton";
import { BoxModal } from "../../../components/boxModal";
import ReactModal from "react-modal";
import { CancelEntry, EntryCancel, FilterButton, FilterItem, LoaderDiv } from "../../../components/E-check/style";
import { claimKey, cancelClaimKey, replyClaimKey } from "../../../services/pix.service"; 
import { usePixKey } from "../../../hooks/usePixKey";



export function PixKey({ type, keyValue, id, status, showConfirmationCodeModal, setTypeKey, showKeyDeletedModal}) {
  const [isCopied, setIsCopied] = useState(false)
  const [deleteActionsIsOpen, setDeleteActionsIsOpen] = useState(false)
  const [typeValue, setTypeValue] = useState('')
  const [statusValue, setStatusValue] = useState('')
  const [portabilityModal, setPortabilityModal] = useState(false);
  const [requestPortabilityModal, setRequestPortabilityModal] = useState(false);
  const [cancelPortabilityModal, setCancelPortabilityModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const { fetchPixKeys } = usePixKey();

  const acceptExternalClaim = 1;
  const rejectExternalClaim = 0;

  const getTypeDescription = () =>{
    const typeResult = pixTypes.filter((item)=> item.key == type);
    setTypeValue(typeResult[0].value);
  }
  const getStatusDescription = () =>{
    const statusResult = pixKeyStatus.filter((item)=> item.key == status);
    setStatusValue(statusResult[0].value);
  }

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(keyValue);

      setIsCopied(true)

      setTimeout(() => {
        setIsCopied(false)
      }, 3000);
    } catch(err) {
      toast.error('Não foi possivel copiar a chave pix.')
    }
  }

  function handleCancelDelete() {
    setDeleteActionsIsOpen(false)
  }

  function handleDelete() {
    setLoader(true);
    deleteKey(id, navigate).then(isDeleted => {
      if(isDeleted){
        ShowKeyDeletedModal();
        setLoader(false);
        fetchPixKeys();
      }
    })

    setDeleteActionsIsOpen(false)
  }

  async function handlePortabilityKey() {
    const payload = {
      pixKey: keyValue,
      keyType: type,
    }
    const claimResponse = await claimKey(payload);
    if(claimResponse){
      setPortabilityModal(false);
      fetchPixKeys();
    }
  }

  async function handleCancelPortabilityKey() {
    const payload = {
      pixKey: keyValue,
      keyType: type,
    }
    const cancelClaimResponse = await cancelClaimKey(payload);
    if(cancelClaimResponse){
      setCancelPortabilityModal(false);
      fetchPixKeys();
    }
  }

  async function handleRequestPortabilityKey(confirmation) {
    const payload = {
      pixKey: keyValue,
      keyType: type,
      confirmation,
    }
    const replyClaimResponse = await replyClaimKey(payload);
    if(replyClaimResponse){
      setRequestPortabilityModal(false);
      fetchPixKeys();
    }
  }


  useEffect(() => {
    if (type) {
      getTypeDescription()
    }
  }, [type])

  useEffect(() => {
    if (status) {
      getStatusDescription()
    }
  }, [status])
  function ShowConfirmationCodeModal(){
    setTypeKey(type);
    showConfirmationCodeModal()
  }

  function ShowKeyDeletedModal(){
    setTypeKey(type);
    showKeyDeletedModal()
  }
  return (
    <div className="pix-key-container">
      <div className="pix-key-info">


        <div className="pix-key-content">

          <div className="info-header">
            <div className="type-value">{typeValue}</div>
            <span className="separator">-</span>
            <div className="info-status-title">Status:</div>
            <div className="info-status-value">{statusValue}</div>
            <div className="info-status-button">

              {status == PIX_KEY_STATUS_LIST.NOT_CONFIRMED && (<InfoStatusButton click={() => ShowConfirmationCodeModal()} label={'Inserir Código de Confirmação'}/>)}
              {status == PIX_KEY_STATUS_LIST.OWNERSHIP_ERROR && (<InfoStatusButton click={()=> setPortabilityModal(true)} label={'Solicitar Portabilidade'}/>)}
              {status == PIX_KEY_STATUS_LIST.PORTABILITY_ERROR && (<InfoStatusButton click={()=> setPortabilityModal(true)} label={'Solicitar Portabilidade'}/>)}
              {status == PIX_KEY_STATUS_LIST.CLAIMING && (<InfoStatusButton click={()=> setCancelPortabilityModal(true)} label={'Cancelar Portabilidade'}/>)}
              {status == PIX_KEY_STATUS_LIST.EXTERNAL_CLAIMING && (<InfoStatusButton click={()=> setRequestPortabilityModal(true)} label={'Responder Portabilidade'}/>)}
               
       <ReactModal
          isOpen={portabilityModal}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title="Confirmação de portabilidade"
            close={() => setPortabilityModal(false)}
            maxWidth={500}
          >
            <FilterItem>
              <CancelEntry>
                Tem certeza que deseja solicitar a portabilidade da chave?
              </CancelEntry>
            </FilterItem>
            <LoaderDiv>
              <EntryCancel onClick={() => setPortabilityModal(false)}>
                Não
              </EntryCancel>
              <FilterButton onClick={() => handlePortabilityKey()}>
                Sim
              </FilterButton>
            </LoaderDiv>
          </BoxModal>
        </ReactModal>

        <ReactModal
          isOpen={cancelPortabilityModal}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title="Confirmação de cancelamento de portabilidade"
            close={() => setCancelPortabilityModal(false)}
            maxWidth={500}
          >
            <FilterItem>
              <CancelEntry>
                Tem certeza que deseja cancelar a portabilidade da chave?
              </CancelEntry>
            </FilterItem>
            <LoaderDiv>
              <EntryCancel onClick={() => setCancelPortabilityModal(false)}>
                Não
              </EntryCancel>
              <FilterButton onClick={() => handleCancelPortabilityKey()}>
                Sim
              </FilterButton>
            </LoaderDiv>
          </BoxModal>
        </ReactModal>

        <ReactModal
          isOpen={requestPortabilityModal}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title="Confirmação de portabilidade"
            close={() => setRequestPortabilityModal(false)}
            maxWidth={500}
          >
            <FilterItem>
              <p>
                Tem certeza que deseja aceitar a portabilidade da chave "{keyValue}" ?
              </p>
            </FilterItem>
            <LoaderDiv>
              <EntryCancel onClick={() => handleRequestPortabilityKey(rejectExternalClaim)}>
                Não
              </EntryCancel>
              <FilterButton onClick={() => handleRequestPortabilityKey(acceptExternalClaim)}>
                Sim
              </FilterButton>
            </LoaderDiv>
          </BoxModal>
        </ReactModal>

            </div>
          </div>
          <strong>{keyValue}</strong>
        </div>

      </div>

      <div className="pix-key-actions">
        <button onClick={copyToClipboard}>
          {
            isCopied
            ? (
              <TbClipboardCheck className="copy-check" size={24} />
            )
            : (
              <TbCopy size={24} />
            )
          }
        </button>
          {
            loader ? <span>Excluindo...</span> : <>{
              deleteActionsIsOpen
              ? (
                <div className="key-delete-actions">
                  <button className="trash-button" onClick={handleCancelDelete}>
                    <BsXSquare size={24} />
                  </button>
                  <button className="delete-confirm-button" onClick={handleDelete}>
                    <BsCheckSquare size={24} />
                  </button>
                </div>
              ) : (
                <button className="trash-button" onClick={() => setDeleteActionsIsOpen(true)}>
                  <TbTrash size={24} />
                </button>
              )
            }</>
          }

      </div>
    </div>
  )
}

const PIX_KEY_STATUS_LIST = {
  ACTIVE: 0,
  INACTIVE: 1,
  PENDING: 2,
  ERROR: 3,
  PORTABILITY_ERROR: 4,
  OWNERSHIP_ERROR: 5,
  NOT_CONFIRMED: 6,
  CLAIMING: 7,
  EXTERNAL_CLAIMING : 8,
}

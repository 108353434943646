import ReactModal from 'react-modal'
import { useState } from 'react';
import { css } from "@emotion/react";
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { CreateKey } from '../../../services/pix.service';
import { useNavigate } from 'react-router';
import { apiV1 } from "../../../lib/axios";
import { formatCnpjCpf, formatPhone } from "../../../utils/formatter";

import './styles.css'
import { FeedBackKeyCreated } from './FeedBackKeyCreated';
import { CreatePix } from './CreatePix';
import { ValidateConfirmationCode } from './ValidateConfirmationCode';
import { FeedBackKeyDeleted } from './FeedBackKeyDeleted';
import { usePixKey } from '../../../hooks/usePixKey';

export function KeysModal({ isOpen, setKeysModalIsOpen}) {
  const [keyIsCreated, setKeyIsCreated] = useState(false)
  const [isWaitDBChange, setIsWaitDBChange] = useState(false);
  const [loader, setLoader] = useState(false);
  const [typeKey, setTypeKey] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isPF, setIsPF] = useState(null);
  const [showValidateConfirmationCodeComponent, setShowValidateConfirmationCodeComponent] = useState(false);
  const [showKeyDeletedFeedback, setShowKeyDeletedFeedback] = useState(false);

  const { fetchPixKeys } = usePixKey();
  const navigate = useNavigate()

  function handleCloseModal() {
    setKeyIsCreated(false);
    CloseConfirmationCodeModal();
    CloseKeyDeletedModal();
    setKeysModalIsOpen(false);
  }

  function handleCreateNewKey(e) {
    setLoader(true)
    e.preventDefault()
    try {
      CreateKey(typeKey, '', navigate)
      .then(response => {
        if(response.isCreated){
            setKeyIsCreated(true);
            fetchPixKeys();
          }
        });
    } catch (error) {
      setKeyIsCreated(false)
      setLoader(false)
      toast.error('Erro ao solicitar nova chave');
    }
  }

  useEffect(()=>{
    if(!userData){
      GetUserDataFromBack();
      fetchPixKeys();
    }

  }, []);

  useEffect(() => {
    if(isWaitDBChange){
      setIsWaitDBChange(false);
    }
  }, [isWaitDBChange]);

  async function GetUserDataFromBack(){
    const endpoint = "/person/show";
    const response = await apiV1.get(endpoint);
    const { phoneNumber, taxNumber, mail } = response.data.data;
    checkIfIsPF(taxNumber);
    const taxNumberFormatted = formatCnpjCpf(taxNumber);
    const phoneNumberFormatted = formatPhone(phoneNumber);
    setUserData({phoneNumber: phoneNumberFormatted, taxNumber: taxNumberFormatted, mail});
  }

  function checkIfIsPF(taxNumber){
    if(taxNumber.length === 11){
      setIsPF(true);
    }else {
      setIsPF(false);
    }
  }
  function ShowConfirmationCodeModal(){
    setShowValidateConfirmationCodeComponent(true);
  }
  function CloseConfirmationCodeModal() {
    setShowValidateConfirmationCodeComponent(false);
  }
  function ShowKeyDeletedModal(){
    setShowKeyDeletedFeedback(true);
  }
  function CloseKeyDeletedModal() {
    setShowKeyDeletedFeedback(false);
  }
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  return (
    <ReactModal
      isOpen={isOpen}
      className={"keys-modal"}
      overlayClassName={"overlay-keys-modal"}
    >
      {keyIsCreated && (<FeedBackKeyCreated handleCloseModal={handleCloseModal} keyType={typeKey}/>)}
      {showKeyDeletedFeedback && (<FeedBackKeyDeleted handleCloseModal={handleCloseModal} keyType={typeKey}/>)}
      {(!keyIsCreated && !showValidateConfirmationCodeComponent && !showKeyDeletedFeedback) && (
          <CreatePix
            handleCloseModal={handleCloseModal}
            userData={userData}
            loader={loader}
            override={override}
            handleCreateNewKey={handleCreateNewKey}
            typeKey={typeKey}
            setTypeKey={setTypeKey}
            showConfirmationCodeModal={ShowConfirmationCodeModal}
            showKeyDeletedModal={ShowKeyDeletedModal}
            closeConfirmationCodeModal={CloseConfirmationCodeModal}
            isPF={isPF} />

        )}
      {showValidateConfirmationCodeComponent && (<ValidateConfirmationCode handleCloseModal={handleCloseModal} typeKey={typeKey}/>)}
    </ReactModal>
  )
}
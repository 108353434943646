import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.css";

import { BrowserRouter } from "react-router-dom";
import Routes from "../src/Routes";
import DataProvider from "./services/context/DataProvider";
import { AuthProvider } from "./contexts/AuthContext";
import { AccountProvider } from "./contexts/AccountContext";
import { EntryProvider } from "./contexts/EntryContext";
import { TedProvider } from "./contexts/TedContext";
import { EcheckProvider } from "./contexts/echeckContext";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./styles/Themes/default";
import { GlobalStyle } from "./styles/global";
import { ClientProvider } from "./contexts/ClientContext";
import { PixKeyProvider } from "./contexts/PixKeyContext";

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />

      <DataProvider>
        <BrowserRouter>
          <AuthProvider>
            <AccountProvider>
              <EntryProvider>
                <TedProvider>
                  <EcheckProvider>
                    <ClientProvider>
                      <PixKeyProvider>
                        <Routes />
                      </PixKeyProvider>
                    </ClientProvider>
                  </EcheckProvider>
                </TedProvider>
              </EntryProvider>
            </AccountProvider>
          </AuthProvider>
        </BrowserRouter>
      </DataProvider>
    </ThemeProvider>
  );
}

export default App;

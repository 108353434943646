import { createContext, useState } from 'react';
import { apiV1 } from '../lib/axios';
import { toast } from 'react-toastify';

export const PixKeyContext = createContext({});

export function PixKeyProvider({ children }) {
  const [pixKeys, setPixKeys] = useState([]);
  const [pixKeysLoader, setPixKeysLoader] = useState(false);

  async function fetchPixKeys() {
    try{
      setPixKeysLoader(true);
      setPixKeys([]);
  
      const pixKeys = await apiV1.post('/pixkey/getKeys/',{});
  
      setPixKeys(pixKeys.data.data);
    } catch(err) {
      toast.error(err.message);
    } finally {
      setPixKeysLoader(false);
    }
  }

  return (
    <PixKeyContext.Provider
      value={{
        pixKeys,
        setPixKeys,
        pixKeysLoader,
        setPixKeysLoader,
        fetchPixKeys,
      }}
    >
      {children}
    </PixKeyContext.Provider>
  );
}

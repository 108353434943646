import React, { useEffect, useRef, useState } from 'react';
import { formatCnpjCpf } from '../../../utils/formatter';
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';
import '../desc-extrato/Style.css';
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';
import { apiV1 } from '../../../lib/axios';
import { getImage } from '../../../services/getAssets';
import { useAuth } from '../../../hooks/useAuth';
export default function (props) {
  const [loader, setLoader] = useState(false);
  const [voucherUrl, setVoucherUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const pdfRef = useRef(null);
  const { user, getProfile } = useAuth();

  async function getInfos() {
    await getProfile();
  }

  useEffect(() => {
    getInfos();
  }, []);

  const handleError = (error) => {
    console.error('Erro ao gerar ou buscar o comprovante:', error);
    toast.error('Erro ao gerar ou buscar o comprovante.');
  };

  const fetchVoucher = async (transactionId) => {
    setLoading(true);
    try { 
      const response = await apiV1.get(`/voucher/url/${transactionId}`); 
      if (response.data?.data) {
        return response.data.data;
      } else {
        console.error('Comprovante não encontrado.');
        return null;
      }
    } catch (error) {
      console.error('Erro ao buscar o comprovante:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };
  const handleVoucherAction = async (transactionData) => {
    setLoading(true);
    try {
      const commonData = buildCommonData(transactionData); 
      const transactionId = await createNewVoucher(commonData); 
  
      if (transactionId) { 
        let url = await fetchVoucher(transactionId); 
  
        if (url) {
          window.open(url, '_blank'); 
        } else {
          console.error('Comprovante não encontrado após a criação.');
        }
      } else {
        console.error('TransactionId não foi gerado.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  const createNewVoucher = async (commonData) => {
    try {
      const response = await apiV1.post('/voucher/generate', commonData);
      const transactionId = response.data.data.transactionId; 
      if (!transactionId) { throw new Error('Comprovante não gerado corretamente.'); } return transactionId;
    } catch (error) {
      handleError(error);
    }
  };
  const buildCommonData = (transactionData) => {
    const rawValue = transactionData.value;
    const value = Math.abs(parseFloat(rawValue));

    if (isNaN(value)) {
      console.error('Valor inválido:', rawValue);
      throw new Error('Valor é obrigatório e deve ser um número.');
    }
    const transferDate = 
    transactionData.type === 'pix' 
      ? transactionData.createdAt 
      : transactionData.transferDate || new Date().toISOString();
      
    return {
      transactionId: String(transactionData.transactionId), 
      transferDate,
      type: transactionData.type,
      receiverName: transactionData.receiverName || 'Não Informado',
      receiverTaxNumber: String(transactionData.receiverTaxNumber || 'Não Informado'),
      receiverBank: String(transactionData.receiverBank || ''),
      receiverBankBranch: String(transactionData.receiverBankBranch || '').padStart(4, '0'),
      receiverBankAccount: Number(transactionData.receiverBankAccount || 0),
      receiverBankAccountDigit: Number(transactionData.receiverBankAccountDigit || 0),
      receiverAccountType: transactionData.receiverAccountType || '',
      payerName: transactionData.payerName || 'Não Informado',
      payerTaxNumber: String(transactionData.payerTaxNumber || ''),
      payerBank: String(transactionData.payerBank || ''),
      payerBankBranch: String(transactionData.payerBankBranch || ''),
      payerBankAccount: Number(transactionData.payerBankAccount || 0),
      payerBankAccountDigit: Number(transactionData.payerBankAccountDigit || 0),
      identifier: String(transactionData.identifier || transactionData.id),
      barCode: transactionData.barCode || '',
      value, 
      url_logo: getImage('logo-preto.png'),
    };
  };
  const handleGetVoucher = async () => {
    setLoader(true);
    await handleVoucherAction(props.data); 
    setLoader(false);
  };
  

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;
  return (
    <div className="entry-details">
      {loader ? (
        <div className="details-container-loading">
          <BeatLoader
            color={'var(--secondary-color)'}
            loading={loader}
            css={override}
            size={20}
          />
        </div>
      ) : (
        <div ref={pdfRef} className="details-container">
          <div>
            <h1 className="title">Detalhamento de Informações</h1>
          </div>
          <div className="d-flex">
            {/* Origem */}
            <div className="col-md-6 origem">
              <h1>Origem</h1>
              <div>
                <label>Nome</label>
                <p>{props.data.payerName || 'Nome não informado'}</p>
              </div>
              <div>
                <label>CPF/CNPJ</label>
                <p>
                  {props.data.payerTaxNumber
                    ? formatCnpjCpf(props.data.payerTaxNumber)
                    : 'CPF/CNPJ não informado'}
                </p>
              </div>
              <div>
                <label>Instituição</label>
                <p>{props.data.payerBank || 'Banco não informado'}</p>
              </div>
              <div>
                <label>Agência</label>
                <p>{props.data.payerBankBranch || 'Agência não informada'}</p>
              </div>
              <div>
                <label>Conta</label>
                <p>
                  {props.data.payerBankAccount &&
                  props.data.payerBankAccountDigit
                    ? props.data.payerBankAccount +
                      ' - ' +
                      props.data.payerBankAccountDigit
                    : 'Conta não informada'}
                </p>
              </div>
            </div>
            {/* Destino */}
            <div className="col-md-6 destino">
              <h1>Destino</h1>
              <div>
                <label>Nome</label>
                <p>{props.data.receiverName || 'Nome não informado'}</p>
              </div>
              <div>
                <label>CPF/CNPJ</label>
                <p>
                  {props.data.receiverTaxNumber
                    ? formatCnpjCpf(props.data.receiverTaxNumber)
                    : 'CPF/CNPJ não informado'}
                </p>
              </div>
              <div>
                <label>Instituição</label>
                <p>{props.data.receiverBank || 'Banco não informado'}</p>
              </div>
              <div>
                <label>Agência</label>
                <p>
                  {props.data.receiverBankBranch || 'Agência não informada'}
                </p>
              </div>
              <div>
                <label>Conta</label>
                <p>
                  {props.data.receiverBankAccount &&
                  props.data.receiverBankAccountDigit
                    ? props.data.receiverBankAccount +
                      ' - ' +
                      props.data.receiverBankAccountDigit
                    : 'Conta não informada'}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="col-md-12">
              <h1>Dados gerais do pagamento</h1>
              <div>
                <label>Identificador</label>
                <p>{props.data.transactionId}</p>
              </div>

              {props.data.barCode && (
                <div>
                  <label>Código de barras</label>
                  <p>{props.data.barCode}</p>
                </div>
              )}
              <div>
                <label>Valor</label>
                <p>R$ {String(props.data.value).replaceAll('.', ',')}</p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      )}
      <div className="desc-actions-container">
        <button onClick={props.closeDetails}>Voltar</button>
        {loader ? (
          <button className="loading-btn" disabled>
            Baixando PDF...
          </button>
        ) : (
          <button onClick={handleGetVoucher}>Baixar PDF</button>
        )}
      </div>
    </div>
  );
}
